
function Reel(props) {

    const rspItems = props.data.map((item, index) => {

        return (
            <div key={index} className="rsp-reel-item">
                <div className="image">
                    <img src={require(`../../assets/images/properties/${props.imagePath}/${item.image}.jpeg`)} alt="" />
                    <div className="title">{item.title}</div>
                    <div className="logo">{item.logo != "" ? <img src={require(`../../assets/images/properties/${props.imagePath}/logo/${item.logo}.svg`)} alt="" /> : null}</div>
                </div>
                <a className="button" href={item.url}>Read more</a>
            </div>
        );
    });

    return (
        <div className="rsp-reel-container">
            <div className="rsp-reel-header">
                <h1>{props.title}</h1>
            </div>
            <div className="rsp-reel">
                <div className="rsp-reel-items">
                    {rspItems}
                </div>
            </div>
        </div>
    );
}

export default Reel;