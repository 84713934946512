

function FollowUs() {

    const links = [
        {
            alt: "Instagram",
            url: "",
            icon: "insta"
        },
        {
            alt: "Youtube",
            url: "",
            icon: "youtube"
        },
        {
            alt: "Telegram",
            url: "",
            icon: "telegram"
        },
        {
            alt: "Linked In",
            url: "",
            icon: "linkedin"
        }
    ];

    const renderedLinks = links.map((item, index) => {
        return (
            <img src={require(`../../assets/icons/${item.icon}.svg`)} alt={item.alt} key={index} />
        );
    });

    return (
        <section className="rsp-follow-us">
            <div className="rsp-follow-us-content">
                <div className="rsp-follow-us-container">
                    <div className="rsp-follow-us-header">
                        <h1>Follow us in social media</h1>
                    </div>
                    <div className="rsp-follow-us-main">
                        <div className="text">
                            Follow us on social networks to keep up to date with all the news
                        </div>
                        <div className="links">
                            {renderedLinks}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default FollowUs;