function Projects() {

    const imageContext = require.context('../../assets/images/projects', false, /\.(svg)$/);
    const images = imageContext.keys().map(imageContext);

    const RenderedImages = () => (
        images.map((item, index) => (
            <div className="rsp-projects-grid-item" key={index}>
                <img
                    src={item}
                    alt=""
                />
            </div>
        ))
    );

    return (
        <section className="rsp-projects">
            <div className="rsp-projects-content">
                <div className="rsp-projects-container">
                    <div className="rsp-projects-header">
                        <h1>Our projects</h1>
                    </div>
                    <div className="rsp-projects-grid">
                        <RenderedImages />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;
