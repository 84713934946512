import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./firebase/firebaseConfig";
import './assets/css/main.scss';
import Home from './pages/Home/';
import NotFound from "./pages/NotFound";

function App() {

  const auth = getAuth();
  const [currentUserUID, setCurrentUserUID] = useState(null);

  /* Auth listener */
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setCurrentUserUID(uid);
    } else {
      // User is signed out
    }
  });
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
