import logo from "../../assets/images/rsp-main.svg"
import inet from "../../assets/icons/inet.svg"
import slideOne from '../../assets/images/slider/slide-1.jpeg'
import slideTwo from '../../assets/images/slider/slide-2.jpeg'
import slideThree from '../../assets/images/slider/slide-3.jpeg'
import Slider from "../Slider";

const Header = () => {

    const images = [slideOne, slideTwo, slideThree];

    return (
        <header className="rsp-header">
            <div className="rsp-header-content">
                <div className="rsp-header-container">
                    <div className="rsp-header-row">
                        <h1>RSP.</h1>
                    </div>
                    <div className="rsp-header-row">
                        <img src={logo} alt="" />
                        <div className="text-block">
                            <p>One platform that brings together all RSP services.
                                Created to ensure convenience and efficiency of working
                                with real estate sector and media, product management,
                                finance, each service is presented in detail, with the
                                ability to quickly navigate and order.</p>
                            <a href="https://rsp.guide" class="link-container">
                                <img src={inet} alt="Иконка" class="link-icon" />
                                <span>RSP.GUIDE</span>
                            </a>
                        </div>
                    </div>
                    <div className="rsp-header-row">
                        <div className="rsp-slider-content">
                            <Slider images={images} />
                            <h1>RSP.</h1>
                        </div>
                        <div className="text-block">
                            <div className="slogan">
                                <p>“Exceed your expectations”</p>
                            </div>
                            <div className="copyright">
                                <p>rock solid properties ©</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
