import React from 'react';
import Layout from '../../components/Layout';

function NotFound() {
    return (
        <Layout>
            <div className="not-found">
                <div className="rsp-content">
                    <div className="rsp-container">
                        <div className="rsp-not-found-main">
                        <h2 className="header">404</h2>
                        <h1 className="title">Page Not Found</h1>
                            <h3 className="subtitle">Oops! We couldn't find the page you're looking for.</h3>
                            <p className="text">
                                Don't worry, it happens to the best of us. Please check the URL for any mistakes and try again.
                                If you believe this is an error, feel free to contact our support team for assistance.
                                In the meantime, you can always go back to the homepage to explore more of our website. <pre />
                                <a href='/'>Click here to go back home</a>.
                                <br /><br />
                                We apologize for any inconvenience caused. Thank you for your understanding!
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default NotFound;


