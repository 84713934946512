import { useContext, useState, useRef } from "react";
import logo from "../../assets/images/rsp-main.svg"
import { ButtonMenu } from '../Button/ButtonMenu';
import { Link } from "react-router-dom";
import { LayoutContext } from "../context";
import { useLocation } from "react-router-dom";

const Navbar = () => {
    
    const location = useLocation()
    const { scrollbar } = useContext(LayoutContext);
    const [isOpen, setIsOpen] = useState(false);
    const stripRef = useRef();

    const data = [
        {
            title: "Main",
            link: "/"
        },
        {
            title: "Products",
            link: "/#products"
        },
        {
            title: "Invest Relations",
            link: "/#invest-relations"
        },
        {
            title: "Careers",
            link: "/careers"
        },
        {
            title: "Contact",
            link: "/#contact"
        },
    ];

    const closeMenu = () => {
        setIsOpen(current => !current);
        document.body.classList.remove("no-scroll");
        scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
    };

    const handleClick = () => {
        setIsOpen(current => !current);

        if (!isOpen) {
            document.body.classList.add("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        } else {
            document.body.classList.remove("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        }
    };

    const navs = data.map((item, index) => {

        const isActive = location.hash === item.link;
        const onClickHandler = isActive ? () => setIsOpen(false) : closeMenu;

        return (
            <Link className="rsp-navbar-nav" key={index} to={item.link} onClick={onClickHandler}>
                <span>{item.title}</span>
            </Link>
        );
    });

    return (
        <nav className={`rsp-navbar ${isOpen ? "-open" : ""}`}>
            <div className="rsp-navbar-fill"></div>
            <div className="rsp-navbar-strip" ref={stripRef}>
                <div className="rsp-navbar-container -lg">
                    <div className="rsp-navbar-grid">
                        <div className="rsp-navbar-grid-col -left" style={{width: "80px"}}>
                            <a className="rsp-navbar-logo" href="/" aria-label="Home">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div className="rsp-navbar-grid-col -center">
                            <div className="rsp-navbar-navs">
                                {navs}
                            </div>
                        </div>
                        <div className="rsp-navbar-grid-col -right">
                            <div className="rsp-navbar-toggle">
                                <ButtonMenu onClick={handleClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
