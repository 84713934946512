import React from 'react';
import ReactDOM from 'react-dom/client';
import { getAuth, signInAnonymously } from "firebase/auth";
import reportWebVitals from './reportWebVitals';
import App from "./App";
import { BrowserRouter } from 'react-router-dom';

const auth = getAuth();

//Anonymous login
signInAnonymously(auth)
  .then(() => {
    console.log("Logined anonymous")
  })
  .catch((error) => {
    const errorMessage = error.message;
    console.log(errorMessage);
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
