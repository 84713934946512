import logo from "../../assets/images/logo.svg"

const Footer = () => {

    return (
        <div className="rsp-footer black-bg">
            <div className="rsp-footer-content">
                <div className="rsp-footer-container">
                    <div className="rsp-footer-row">
                        <img src={logo} alt="" />
                    </div>
                    <div className="rsp-footer-row">
                        <div className="rsp-footer-nav"><a href="">Privacy Policy</a></div>
                    </div>
                    <div className="rsp-footer-row">
                        <div className="rsp-footer-copyright"><span>Ruslan Ermilov © 2023 - All rights reserved.</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
