import Reel from '../../components/Reel';
import realEstateData from '../../data/properties/realEstate/data.json';
import mediaData from '../../data/properties/media/data.json';
import servicesData from '../../data/properties/services/data.json';
import commerceData from '../../data/properties/commerce/data.json';
import financeData from '../../data/properties/finance/data.json';

function Properties() {

    const realEstate = {
        title: "Real Estate",
        data: realEstateData,
        imagePath: "real-estate"
    };

    const mediaPresence = {
        title: "Media Presence",
        data: mediaData,
        imagePath: "media"
    };

    const services = {
        title: "Services",
        data: servicesData,
        imagePath: "services"
    };

    const commerce = {
        title: "Commerce",
        data: commerceData,
        imagePath: "commerce"
    };

    const finance = {
        title: "Finance",
        data: financeData,
        imagePath: "finance"
    };

    return (
        <section className="rsp-properties">
            <div className="rsp-properties-content">
                <div className="rsp-properties-container">
                    <div className="rsp-properties-header">
                        <h1 className="rsp-properties-header">Our properties</h1>
                    </div>
                    <Reel {...realEstate} />
                    <Reel {...mediaPresence} />
                    <Reel {...services} />
                    <Reel {...commerce} />
                    <Reel {...finance} />
                </div>
            </div>
        </section>
    );
}

export default Properties;