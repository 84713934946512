import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Contact from '../../sections/Contact';
import FollowUs from '../../sections/FollowUs';
import Projects from '../../sections/Projects';
import Properties from '../../sections/Properties';

function Home() {
  return (
    <Layout>
      <Header/>
      <Properties />
      <FollowUs />
      <Projects />
      <Contact />
    </Layout>
  );
}

export default Home;

