import data from '../../data/social-links/data.json'

function Contact() {

    const RenderedLinks = () => (
        data.map((item, index) => (
            <div className="profile-link-item" key={index}>
                <a href={item.url}>{item.profile}</a>
            </div>
        ))
    );

    return (
        <section className='rsp-contact'>
            <div className="rsp-contact-content">
                <div className="rsp-contact-container">
                    <div className="rsp-contact-main-wrapper">
                        <div className="rsp-contact-main">
                            <div className="phone">
                                <div className="header">
                                    <img src="" alt="" />
                                    <h2>Phone</h2>
                                </div>
                                <a href="tel:+971585773369">+971 58 577 3369</a>
                            </div>
                            <div className="social">
                                <div className="header">
                                    <img src="" alt="" />
                                    <h2>Social media</h2>
                                </div>
                                <div className="list">
                                    <RenderedLinks />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;